import React from "react"
import {PostLayout} from "./post.js"


export default function Template({data,})  
{
  return (
    <div>    <PostLayout prehead={data.kw.frontmatter.title}
                headline={data.kw.frontmatter.title + " in " + data.geo.frontmatter.title}
                subhead={data.kw.frontmatter.title}
                content={data.kw.frontmatter.title} />
</div>
  );

}

export const pageQuery = graphql`
  query SeoBlogPostQuery($kwNode: String!, $geoNode: String!) {
    kw: markdownRemark(fields: { slug: { eq: $kwNode } }) {
      htmlAst
        html
      frontmatter {
title
      }
    }
    geo: markdownRemark(fields: { slug: { eq: $geoNode } }) {
      htmlAst
        html
      frontmatter {
title
      }
    }
    allGeo: allMarkdownRemark(
      filter: {fields: {seo_type: { eq: "geo" }}}
) {
edges {
node{


      htmlAst
      html
fields {
slug
}
      frontmatter {
        title
      }}
}
}

  }
`;
