import React from "react"
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from "../components/layout"
import Warranty60d from "../components/sixty-day-warranty"
import Warranty3y from "../components/three-year-warranty"
import ContactForm from "../components/contact-form"
import rehypeReact from "rehype-react"


const renderAst = new rehypeReact({
  createElement: React.createElement,
  components: {"warranty-60": Warranty60d,
               "warranty-3y": Warranty3y,
               "contact-form": ContactForm,
              },
   // { "interactive-counter": Counter },
}).Compiler

export function PostLayout({prehead, title, headline, subhead, content, description, keywords})
{
    return (
        <Layout>
          <Helmet
            title={title}
            meta={[
              { name: 'description', content: description },
              { name: 'keywords', content: keywords },
            ]}
          >
            <link rel="stylesheet" type="text/css" href="https://unpkg.com/tachyons@4.10.0/css/tachyons.min.css"/>
          </Helmet>

          <div id="post-content" className="">
            <div className="pt1">
              {prehead &&
              <h2 className="f5 mv3 mv4-ns"
                  dangerouslySetInnerHTML={{ __html: prehead}}/>
              }
              {headline &&
              <h1 className="f2-ns f3 mv3 mv4-ns"
                  dangerouslySetInnerHTML={{ __html: headline}}/>
              }
              {subhead &&
              <h2 className="f5 lh-title i fw1 mv3 mv4-ns" >
                <span className="bg-light-yellow"
                      dangerouslySetInnerHTML={{ __html: subhead}}/>
              </h2>
              }
              <div className="pt2">
                { content }
              </div>
            </div>
          </div>
        </Layout>
    );
  
}

export default function Template({
    data, // this prop will be injected by the GraphQL query below.
}) {
    const { markdownRemark } = data; // data.markdownRemark holds our post data
    const { frontmatter, htmlAst } = markdownRemark;
    return (
        <PostLayout prehead={frontmatter.prehead}
                    headline={frontmatter.headline}
                    subhead={frontmatter.subhead}
                    title={frontmatter.seo_title || frontmatter.title}
                    description={frontmatter.description || frontmatter.subhead}
                    content={renderAst(htmlAst)} />
    );
}

export const pageQuery = graphql`
  query BlogPostByPath($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      htmlAst
        html
      frontmatter {
        path
        title
        prehead
        headline
        subhead
      }
    }
  }
`;
